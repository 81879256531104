<template>
    <div class="who-we-are" v-if="pageData">
        <div class="intro-full-w bg-img">
            <intro-page :pageId="currentPageId"></intro-page>
        </div>
        <div class="two-column-layout section">
        <div class="container">
        <div class="row mt-5">
            <div class="col-lg-6 order-lg-1 order-md-2 text">
            <h6>
                ABOUT US
            </h6>
            <h3 class="light">
                {{pageData.sissa.sissa_titolo}}
            </h3>
            <p v-html="pageData.sissa.sissa_testo"></p>
            <div class="footer-section">
                <img src="../assets/logo-sissa-dark.png" width="97px" height="auto" alt="">
                <a href="https://www.sissa.it/" target="_blank"><button class="cta-outline"><img src="@/assets/icon-website.svg" width="27px" alt="website" />
SISSA Official  Website</button></a>
            </div>
            </div>
            <div class="col-lg-6 order-lg-2 order-md-1 image">
            </div>
        </div>
        <div class="row mt-5">
            <div class="col-lg-6 order-lg-2 order-md-1 text">
            <h3 class="light">
                {{pageData.ictp.ictp_titolo}}
            </h3>
            <p v-html="pageData.ictp.ictp_testo"></p>
                        <div class="footer-section">
                <img src="../assets/logo-ictp-blue.png" width="245px" height="auto" alt="">
                 <a href="https://www.ictp.it/" target="_blank"><button class="cta-outline"><img width="27px" src="@/assets/icon-website.svg" alt="website" />
ICTP Official  Website</button></a>
            </div>

            </div>
            <div class="col-lg-6 order-lg-1 order-md-2 image">
            </div>
        </div>
        </div>
    </div>
    </div>
    <div class="loading" v-else></div>
</template>
<script>

import IntroPage from '../components/IntroPage.vue'
export default {
    name:"who-we-are",
    components: {
        IntroPage
    },
    data:()=>{
        return {
            currentPageId:"91708eec-74f4-43cd-a2e5-3b73b97ff96b",
            pageData:null,
        }
    },
    methods:{
        fetchPageData(){
            fetch(`${process.env.VUE_APP_ENDPOINT}/custom-texts/who-we-are`)
            .then((response) => {
                return response.json();
            })
            .then((json) => {
                this.pageData = json.data;
            })
            .catch(function (error) {
                console.log("Request failed", error);
            });
        },
    },
    mounted(){
        this.fetchPageData()
    }
}


</script>
<style lang="scss" scoped>

.who-we-are .bg-img{
    background-image: url("../assets/into-who-we-are.png") ;
}
.who-we-are .two-column-layout {
    .row:nth-child(odd) .image{
        background-image: url("../assets/who-we-are-sissa.jpg") ;
    }
    .row:nth-child(even) .image{
        background-image: url("../assets/who-we-are-ictp.jpg") ;
    }
}

.footer-section {
    display: flex;
    align-items: flex-end;
    margin-top: 5rem;
    .cta-outline {
    padding: 8px 20px;
    }
    button {
        white-space: nowrap;
        margin-left: 2rem;
        max-height: 60px;
        padding: 5px 25px;
        img {
            margin-right: 10px;
        }
    }
}

.footer-section {
    display: flex;
    align-items: flex-end;
    margin-top: 5rem;
    .cta-outline {
    padding: 8px 20px;
    }
    button {
        white-space: nowrap;
        margin-left: 2rem;
        max-height: 60px;
        padding: 5px 25px;
        img {
            margin-right: 10px;
        }
    }
}


@media (max-width:992px) {
    .who-we-are .bg-img{
        background-image: url("../assets/filigrana-mobile.svg") ;
    }
}

@media (max-width:576px) {
    .footer-section {
        flex-direction: column;
        align-items: center;
        button{
            margin-bottom: 2rem;
            margin-top: 1rem;
            margin-left: 0;
        }
    }
}


</style>